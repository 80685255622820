import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import VueCesium from 'vue-cesium'
import 'vue-cesium/dist/index.css'

import { apiRequest } from '@/api/api.js';
import request from '@/utils/request';
import VueECharts from 'vue-echarts';
import 'echarts'; // 引入 ECharts 核心库
import "echarts-gl";

// 默认配置参数
import * as dftCfg from '@/utils/dftCfg'
const app = createApp(App)

// Axios
app.config.globalProperties.$http = request;
app.config.globalProperties.axios = axios
app.config.globalProperties.$dftCfg = dftCfg

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
// const resizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends resizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 200);
//     super(callback);
//   }
// };

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, { locale: zhCn })
app.use(router)
app.use(VueCesium, {
  cesiumPath: '/js/cesium/Cesium.js'
})
app.component('v-chart', VueECharts);
app.mount('#app')