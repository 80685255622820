<template>
  <div class="common-layout">
    <el-container>
      <el-header class="header">

        <div class=" row upper">
          <el-col :span="8">
            <div>
              <img src="./css/img/logo.png" alt="">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="main-title">
              <span>
                X波段相控阵天气雷达产品软件
              </span>
            </div>
          </el-col>
          <el-col :span="8" class="user-control">

            <div v-if="showHeaderMenu" style="display: flex;align-items: center;">
              <el-icon size="24" color="white">
                <Avatar />
              </el-icon>
              <!-- <img src="./css/img/userControl.png" alt=""> -->
              <el-text style="color:white;font-size: large;">{{userName}}，欢迎您</el-text>
              <el-button type="primary" @click="handleClick_logout" style="background: #00000000;border: 0;"><el-icon
                  size="24" color="white">
                  <SwitchButton />
                </el-icon></el-button>
            </div>

          </el-col>




        </div>
        <div class="row lower" v-if="showHeaderMenu">
          <el-menu :ellipsis="false" background-color="#ffffff00" text-color="white" active-text-color="white"
            mode="horizontal" :router="true" class="menu-container">
            <div class="menu-items">
              <div><el-menu-item index="singleSite" v-if="showMenuList.includes('单站产品')">单站产品</el-menu-item></div>
              <div><el-menu-item index="multiSite" v-if="showMenuList.includes('组网产品')">组网产品</el-menu-item></div>
              <div><el-menu-item index="healthStatus" v-if="showMenuList.includes('健康状态')">健康状态</el-menu-item></div>
              <div><el-menu-item index="dataRequest" v-if="showMenuList.includes('数据申请')">数据申请</el-menu-item></div>
            </div>
            <div></div>
            <div class="updateTime"><span v-if="showProductDataTime" >产品数据时间 2024-12-11 10:25:30</span></div>
            <div></div>
            <div class="menu-items">
              <div><el-menu-item index="productDist" v-if="showMenuList.includes('产品分发')">产品分发</el-menu-item></div>
              <div><el-menu-item index="paramConfig" v-if="showMenuList.includes('参数配置')">参数配置</el-menu-item></div>
              <div><el-menu-item index="userMgr" v-if="showMenuList.includes('用户管理')">用户管理</el-menu-item></div>
              <div><el-menu-item index="logView" v-if="showMenuList.includes('日志信息')">日志信息</el-menu-item></div>
            </div>

          </el-menu>

        </div>
        <!-- <el-menu ellipsis class="el-menu-popper-demo" mode="horizontal" :router="true">
          <el-menu-item index="singleSite">单站产品</el-menu-item>
          <el-menu-item index="multiSite">多站产品</el-menu-item>
          <el-menu-item index="healthStatus">健康状态</el-menu-item>
          
        </el-menu> -->


      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>


</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      showHeaderMenu: false,  // 控制元素A显示与否
      showProductDataTime: false,
      showMenuList: [],
      userName:undefined,
    }
  },
  watch: {
    // 监听路由变化，实时更新 showHeaderMenu
    $route(to) {
      this.updateElementAVisibility(to)
    }
  },
  methods: {
    // 根据当前路由判断是否显示元素A
    updateElementAVisibility(route) {
      if (route.name === 'login') {
        this.showHeaderMenu = false
      } else {
        this.showHeaderMenu = true
        const menuList = JSON.parse(sessionStorage.getItem("menuList")) || [];
        this.showMenuList = menuList.map(menu => menu.name);
      }
      this.showProductDataTime = route.name === 'singleSite' ? true : false
      this.userName=JSON.parse(sessionStorage.getItem('userName'))
    },

    handleClick_logout() {

      // sessionStorage.removeItem("menuList")
      // sessionStorage.removeItem("token")
      sessionStorage.clear();
      this.$router.push({ name: 'login' });
      this.$message({
        showClose: true,
        message: '已登出',
        type: 'success',
      });
    }
  },
  created() {
    // 初始化时检查当前路由
    this.updateElementAVisibility(this.$route)
  }
}
</script>

<style scoped>
.common-layout {
  width: 100%;
}

.common-layout .el-main {
  padding: 0;
}

.header {
  height: 100px;
  padding: 0;
  background: url('./css/img/bg.png'), url('./css/img/bg0.png');
  background-size: 100% 100%;
  justify-content: center;

  .upper {
    height: 55%;
    margin: 0px 9%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main-title {
      color: white;
      font-family: 优设标题黑;
      font-size: 35px;
      /* transform: translateY(25%); */
      letter-spacing: 5px;
    }

    .user-control {
      display: flex;
      justify-content: flex-end;
    }
  }

  .lower {
    height: 45%;
    margin: 0px 8%;




    .menu-container {
      width: 100%;
      display: grid;
      /* 列的比例 */
      grid-template-columns: 2fr 1fr 2fr 1fr 2fr;
      border-bottom: 0;

      .menu-items {
        display: flex;
        justify-content: space-between;
        height: 40px;
      }

      .el-menu-item {
        font-size: large;
        font-weight: bold;
        /* flex: 1; */
        /* padding: 0; */
        justify-content: left;
        height: 100%;
      }

      .el-menu-item.is-active {
        border: 0;
        color: white;
        background: linear-gradient(91.16deg, rgba(22, 103, 232, 0) 0.1%, rgba(0, 119, 255, 0.89) 48.239%, rgba(0, 136, 255, 0) 99.3%);
      }


    }




    .el-menu--horizontal {
      --el-menu-horizontal-height: 40px;
    }

    .updateTime {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 微软雅黑;
      font-size: 15px;
      font-weight: bold;
      color: white;

    }


  }

  .menu-item {
    display: inline-flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .el-menu-popper-demo {
    height: 50px;
    border-bottom: 0;


  }
}
</style>
