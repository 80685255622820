import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/common/layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
    component: HomeView,
    meta: {
      title: 'X波段相控阵天气雷达——产品软件',
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/common/login/index.vue'),
        meta: {
          title: '登录页面',
        },
      },
      {
        path: '/singleSite',
        name: 'singleSite',
        component: () => import('@/views/singleSite/index.vue'),
        meta: {
          title: '单站产品',
        },
      },
      {
        path: '/multiSite',
        name: 'multiSite',
        component: () => import('@/views/multiSite/index.vue'),
        meta: {
          title: '组网产品',
        }
      },
      {
        path: '/healthStatus',
        name: 'healthStatus',
        component: () => import('@/views/healthStatus/index.vue'),
        meta: {
          title: '健康状态',
        }
      },
      {
        path: '/productDist',
        name: 'productDist',
        component: () => import('@/views/productDist/index.vue'),
        meta: {
          title: '数据分发',
        }
      },
      {
        path: '/dataRequest',
        name: 'dataRequest',
        component: () => import('@/views/dataRequest/index.vue'),
        meta: {
          title: '数据申请',
        }
      },
      {
        path: '/paramConfig',
        name: 'paramConfig',
        redirect: 'systemParam',
        component: () => import('@/views/paramConfig/index.vue'),
        meta: {
          title: '参数配置',
        },
        children: [
          {
            path: '/systemParam',
            name: 'systemParam',
            component: () => import('@/views/paramConfig/systemParam.vue'),
            meta: {
              title: '系统参数配置',
            },
          },
          {
            path: '/dataFormatTrans',
            name: 'dataFormatTrans',
            component: () => import('@/views/paramConfig/dataFormatTrans.vue'),
            meta: {
              title: '数据格式转换',
            },
          },
          {
            path: '/picSaving',
            name: 'picSaving',
            component: () => import('@/views/paramConfig/picSaving.vue'),
            meta: {
              title: '图片自动保存',
            },
          },
          {
            path: '/dataPreprocessing',
            name: 'dataPreprocessing',
            component: () => import('@/views/paramConfig/dataPreprocessing.vue'),
            meta: {
              title: '数据预处理配置',
            },
          },
          {
            path: '/productConfig',
            name: 'productConfig',
            component: () => import('@/views/paramConfig/productConfig.vue'),
            meta: {
              title: '产品参数配置',
            },
          },
          {
            path: '/productGenList',
            name: 'productGenList',
            component: () => import('@/views/paramConfig/productGenList.vue'),
            meta: {
              title: '产品生成列表',
            },
          },
          {
            path: '/weatherAlarm',
            name: 'weatherAlarm',
            component: () => import('@/views/paramConfig/weatherAlarm.vue'),
            meta: {
              title: '危险天气预警',
            },
          },
          {
            path: '/colorTable',
            name: 'colorTable',
            component: () => import('@/views/paramConfig/colorTable.vue'),
            meta: {
              title: '产品色标配置',
            },
          },
          {
            path: '/distanceCircleParam',
            name: 'distanceCircleParam',
            component: () => import('@/views/paramConfig/distanceCircleParam.vue'),
            meta: {
              title: '距离圈参数',
            },
          },
          {
            path: '/geoInfo',
            name: 'geoInfo',
            component: () => import('@/views/paramConfig/geoInfo.vue'),
            meta: {
              title: '地理信息显示',
            },
          },
          {
            path: '/garbageCollection',
            name: 'garbageCollection',
            component: () => import('@/views/paramConfig/garbageCollection.vue'),
            meta: {
              title: '数据自动清理',
            },
          },
          {
            path: '/userCustomLayer',
            name: 'userCustomLayer',
            component: () => import('@/views/paramConfig/userCustomLayer.vue'),
            meta: {
              title: '用户自定义图层',
            },
          },
          {
            path: '/networkParam',
            name: 'networkParam',
            component: () => import('@/views/paramConfig/networkParam.vue'),
            meta: {
              title: '网络参数配置',
            },
          },
        ]
      },
      {
        path: '/userMgr',
        name: 'userMgr',
        component: () => import('@/views/userMgr/index.vue'),
        meta: {
          title: '用户管理',
        }
      },
      {
        path: '/logView',
        name: 'logView',
        component: () => import('@/views/logView/index.vue'),
        meta: {
          title: '日志信息',
        }
      },
      {
        path: '/terrain',
        name: 'terrain',
        component: () => import('@/views/test/terrain.vue'),
        meta: {
          title: 'terrain'
        }
      },
      {
        path: '/upload',
        name: 'upload',
        component: () => import('@/views/test/upload.vue'),
        meta: {
          title: 'upload'
        }
      },
      {
        path: '/3dtest',
        name: '3dtest',
        component: () => import('@/views/test/td.vue'),
        meta: {
          title: '3D',
        },
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});

export default router
