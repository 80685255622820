export const userCustomLayer = { pins: [], polylines: [], polygons: [] }

export const distanceCircle = {
    // 距离圈图像缩放倍数
    scaleFactor: 4,
    // 圆圈弧线
    // 以固定距离画线
    circleFixedDist: false,
    // 弧线间隔KM
    circleIntervalDist: 10,
    // 圈数
    circleCount: 5,
    // 角度分割线
    // 显示分割线
    lineVisible: true,
    // 角度间隔
    lineAngle: 30,
    // 显示最内圈
    lineInnerVisible: false,
    // 线上距离数字显示
    lineMark: true,
    // 线宽px
    lineWeight: 2,
    // 线颜色
    lineColor: "#000000",
    // 探测范围
    // 显示探测范围
    shadingVisible: true,
    // 探测范围填充颜色
    shadingColor: "#a8a8a81a"
}

export const system = {
    // 本雷达的网络编号
    siteNetworkId: "ZBAD",
    // 启动时，默认启用产品自动更新（已设置）
    autoUpdateDefaultOn: false,
    // 自动更新时长（已设置）
    autoUpdateInterval: 5.5,
    // 启动时，默认启用产品动态显示（已设置）
    dynamicDisplayDefaultOn: false,
    // 动态显示时长（已设置）
    dynamicDisplayInterval: 3,
    // 动态显示时，产品序列的长度
    dynamicDisplayLoopLength: 60,
    // 气象产品显示页中，时间轴的长度
    timeBarLength: 60,
    // 基数据
    // 在本地进行基数据的保存
    dataStorge_Base_SavingOn: true,
    // 保存时长
    dataStorge_Base_SavingDay: 10,
    // 基数据保存路径
    dataStorge_Base_SavingPath: "/data/base",
    // 预处理后数据
    // 是否对预处理后的数据进行自动清理
    dataStorge_Preprocess_AutoCleanOn: true,
    // 保存时长
    dataStorge_Preprocess_SavingDay: 10,
    // 预处理后数据保存路径
    dataStorge_Preprocess_SavingPath: "/data/preprocess",
    // 产品数据
    // 是否对产品数据进行自动清理
    dataStorge_Product_AutoCleanOn: true,
    // 保存时长
    dataStorge_Product_SavingDay: 10,
    // 产品数据保存路径
    dataStorge_Product_SavingPath: "/data/product",
}

export const geoInfo = {
    cityName: {
        fontFamily: "黑体",
        fontSize: 16.0,
        fontColor: "#000000"
    },
    districtName: {
        fontFamily: "黑体",
        fontSize: 16.0,
        fontColor: "#000000"
    },
    countryLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    provinceLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    cityLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    districtLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    roadLine: {
        width: 2.0,
        color: "#c9c9c9"
    },
    lakePolygon: {
        outlineWidth: 2.0,
        outlineColor: "#00FFBB",
        fillColor: "#06ACFF"
    }
}

export const opts_fontFamily = [
    {
        "value": "黑体",
        "label": "黑体"
    },
    {
        "value": "Microsoft YaHei UI",
        "label": "微软雅黑"
    },
    {
        "value": "楷体",
        "label": "楷体"
    }
]

export const network = {
    backendAddress: "http://127.0.0.1:3000"
}